//
// Gatsby Tinongo template for /sportart/{slug} pages
//

import React from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';
import { html } from '../utils';

import AppShell from '../components/app-shell';
import Content from '../components/content';
import Section from '../components/section';
import SEO from '../components/seo';
import SportFacts from '../components/sport-facts';
import Toc from '../components/toc';
import Toolbar from '../components/toolbar';
import SocialShare from '../components/material-ui/toolbar-social-share';
import YoutubeVideo from '../components/youtube-video';

import styles from './sportart-pages.module.scss';
import stylesToolbar from '../components/toolbar.module.scss';

export default function Sport({ data }) {
  const {
    titel,
    slug,
    searchQuery,
    metaDescription,
    youtubeVideoId,
    image,
    einleitung,
    ziel,
    warum,
    allgemeines,
    zeitaufwand,
    kosten,
    verbreitung,
    karriere,
    gesundheit,
  } = data.contentfulSportart;
  const { siteUrl } = data.site.siteMetadata;

  const classes = classNames({
    [`${styles.sport}`]: true,
  });

  // Back link to '/suche/sportarten'
  const back = (
    <Link
      to="/suche/sportarten"
      className={`${stylesToolbar.toolbarItem} ${stylesToolbar.toolbarItemBack}`}>
      <i className="material-icons" aria-hidden="true">
        arrow_back
      </i>
      <span>Suche</span>
    </Link>
  );

  // Link to '/suche/vereine/sportart/{slug}'
  const toClubSearch = (
    <Link
      to={`/suche/vereine/sportart/${
        searchQuery
          ? encodeURIComponent(searchQuery)
          : encodeURIComponent(titel)
      }`}
      className={stylesToolbar.toolbarItem}>
      <i className="material-icons" aria-hidden="true">
        place
      </i>
      <span>{titel} Vereine</span>
    </Link>
  );

  const socialShare = (
    <div className={stylesToolbar.toolbarSocialShare}>
      <SocialShare
        message={metaDescription}
        link={`${siteUrl}/sportart/${slug}`}
      />
    </div>
  );

  // Article header
  const header = (
    <header>
      <h1 className="pageTitle">{titel}</h1>
    </header>
  );

  // Youtube video
  const video = (
    <div className={styles.video}>
      {youtubeVideoId && (
        <YoutubeVideo title="Video" videoID={youtubeVideoId} />
      )}
      {!youtubeVideoId && <span>Leider kein Video vorhanden...</span>}
    </div>
  );

  // Table of contents and facts
  const tocItems = [
    { href: '#sportart', text: 'Die Sportart' },
    { href: '#ziel', text: 'Was ist das Ziel?' },
    { href: '#warum', text: `Warum ${titel}?` },
    { href: '#allgemeines', text: 'Allgemeines' },
    { href: '#zeitaufwand', text: 'Zeitaufwand' },
    { href: '#kosten', text: 'Kosten' },
    { href: '#verbreitung', text: 'Verbreitung' },
    { href: '#karriere', text: 'Karriere' },
    { href: '#gesundheit', text: 'Gesundheit' },
  ];

  const tocAndFacts = (
    <div className={styles.tocAndFacts}>
      <div className={styles.toc}>
        <Toc title="Inhalt" items={tocItems} />
      </div>

      <div className={styles.facts}>
        <SportFacts content={data.contentfulSportart} />
      </div>
    </div>
  );

  // Text content
  const text = (
    <div>
      {/* Einleitung */}
      {einleitung && (
        <Section title="Die Sportart" id="sportart" withDivider>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(einleitung.einleitung)}
          />
        </Section>
      )}

      {/* Ziel */}
      {ziel && (
        <Section title="Was ist das Ziel?" id="ziel" withDivider>
          <div className="bodyText" dangerouslySetInnerHTML={html(ziel.ziel)} />
        </Section>
      )}

      {/* Warum */}
      {warum && (
        <Section title={`Warum ${titel}?`} id="warum" withDivider>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(warum.warum)}
          />
        </Section>
      )}

      {/* Allgemeines */}
      {allgemeines && (
        <Section title="Allgemeines" id="allgemeines" withDivider>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(allgemeines.allgemeines)}
          />
        </Section>
      )}

      {/* Zeitaufwand */}
      {zeitaufwand && (
        <Section title="Zeitaufwand" id="zeitaufwand" withDivider>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(zeitaufwand.zeitaufwand)}
          />
        </Section>
      )}

      {/* Kosten */}
      {kosten && (
        <Section title="Kosten" id="kosten" withDivider>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(kosten.kosten)}
          />
        </Section>
      )}

      {/* Verbreitung */}
      {verbreitung && (
        <Section title="Verbreitung" id="verbreitung" withDivider>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(verbreitung.verbreitung)}
          />
        </Section>
      )}

      {/* Karriere */}
      {karriere && (
        <Section title="Karriere" id="karriere" withDivider>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(karriere.karriere)}
          />
        </Section>
      )}

      {/* Gesundheit */}
      {gesundheit && (
        <Section title="Gesundheit" id="gesundheit" withDivider>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(gesundheit.gesundheit)}
          />
        </Section>
      )}
    </div>
  );

  return (
    <>
      <SEO
        title={titel}
        description={metaDescription}
        pathname={`/sportart/${slug}`}
        image={`https:${image.file.url}`}
      />
      <AppShell>
        <Content hasToolbar>
          <Toolbar
            leftChildren={back}
            centerChildren={toClubSearch}
            rightChildren={socialShare}
          />
          <article className={classes}>
            {header}
            {video}
            {tocAndFacts}
            {text}
          </article>
        </Content>
      </AppShell>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulSportart(slug: { eq: $slug }) {
      titel
      slug
      searchQuery
      metaDescription
      youtubeVideoId
      eintrittsalter
      typDerSportart
      einzelMannschaftssport
      indoorOutdoor
      kostenProJahrAb
      kostenProJahrBis
      zeitaufwandProWoche
      websiteDesVerbandes
      vereinsuebersicht
      image {
        title
        description
        file {
          url
        }
      }
      einleitung {
        einleitung
      }
      ziel {
        ziel
      }
      warum {
        warum
      }
      allgemeines {
        allgemeines
      }
      zeitaufwand {
        zeitaufwand
      }
      kosten {
        kosten
      }
      verbreitung {
        verbreitung
      }
      karriere {
        karriere
      }
      gesundheit {
        gesundheit
      }
    }
  }
`;
