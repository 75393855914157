/* React Tinongo <Section> component */

import React from 'react';
import classNames from 'classnames';

import styles from './section.module.scss';

/* <Section> component */
function Section(props) {
  const classes = classNames({
    [`${styles.section}`]: true,
    [`${styles.sectionDefault}`]: !props.fullWidth,
    [`${styles.sectionFullWidth}`]: props.fullWidth,
    [`${styles.sectionDark}`]: props.dark,
    [`${styles.sectionDivider}`]: props.withDivider,
    [`${props.className}`]: props.className,
  });

  return (
    <section className={classes}>
      <div>
        {props.title && !props.id && (
          <h1 className="sectionTitle">{props.title}</h1>
        )}
        {props.title && props.id && (
          <h1 className="sectionTitle" id={props.id}>
            {props.title}
          </h1>
        )}
        {props.children}
      </div>
    </section>
  );
}

export default Section;
