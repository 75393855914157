import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import {
  Facebook,
  Linkedin,
  Mail,
  Twitter,
  Whatsapp,
} from 'react-social-sharing';

export default function (props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const link = props.link;
  const message = props.message;

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        startIcon={<Icon>share</Icon>}>
        Share
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Facebook small message={message} link={link} />
        <Twitter small message={message} link={link} />
        <Linkedin small message={message} link={link} />
        <Whatsapp small message={message} link={link} />
        <Mail
          small
          subject={`Tinongo – Finde den Sport für Dein Kind: ${link}`}
          body={message}
          link={link}
        />
      </Popover>
    </div>
  );
}
