/* React Tinongo <Toolbar> component */

import React from 'react';

import styles from './toolbar.module.scss';

/* <Toolbar> component */
function Toolbar(props) {
  return (
    <div className={styles.toolbar}>
      {props.leftChildren && (
        <div className={styles.toolbarLeft}>{props.leftChildren}</div>
      )}
      {props.centerChildren && (
        <div className={styles.toolbarCenter}>{props.centerChildren}</div>
      )}
      {props.rightChildren && (
        <div className={styles.toolbarRight}>{props.rightChildren}</div>
      )}
    </div>
  );
}

export default Toolbar;
