/* React Tinongo <SportFacts> component */

import React from 'react';
import { Link } from 'gatsby';
import styles from './sport-facts.module.scss';

/* <SportFacts> component */
function SportFacts(props) {
  const checkedItems = (arr) => {
    return arr.map((item) => (
      <span key={item}>
        <i className="material-icons" aria-hidden="true">
          check_circle
        </i>
        {item}
      </span>
    ));
  };

  return (
    <dl className={styles.sportFacts}>
      <dt>Geeignet ab</dt>
      <dd>
        <span>
          <i className="material-icons" aria-hidden="true">
            cake
          </i>
          {props.content.eintrittsalter}
        </span>
      </dd>
      <dt>Typ der Sportart</dt>
      <dd>{checkedItems(props.content.typDerSportart)}</dd>
      <dt>Einzel- / Mannschaftssport</dt>
      <dd>{checkedItems(props.content.einzelMannschaftssport)}</dd>
      <dt>Indoor / Outdoor</dt>
      <dd>{checkedItems(props.content.indoorOutdoor)}</dd>
      <dt>Kosten pro Jahr von / bis</dt>
      <dd>
        <span>
          <i className="material-icons" aria-hidden="true">
            euro_symbol
          </i>
          {`${props.content.kostenProJahrAb},- bis ${props.content.kostenProJahrBis},-`}
        </span>
      </dd>
      <dt>Zeitaufwand pro Woche</dt>
      <dd>
        <span>
          <i className="material-icons" aria-hidden="true">
            timer
          </i>
          {props.content.zeitaufwandProWoche}
        </span>
      </dd>
      <dt>Website des Verbands</dt>
      <dd>
        <span className={styles.sportFactsLinkedItem}>
          <a
            href={props.content.websiteDesVerbandes}
            target="_blank"
            rel="noopener noreferrer">
            {props.content.websiteDesVerbandes}
          </a>
          <i className="material-icons" aria-hidden="true">
            link
          </i>
        </span>
      </dd>
      <dt>Vereinsübersicht</dt>
      <dd>
        <span className={styles.sportFactsLinkedItem}>
          <Link
            to={`/suche/vereine/sportart/${
              props.content.searchQuery
                ? encodeURIComponent(props.content.searchQuery)
                : encodeURIComponent(props.content.titel)
            }`}>
            {`${props.content.titel} Vereine auf Tinongo`}
          </Link>
          <i className="material-icons" aria-hidden="true">
            arrow_forward
          </i>
        </span>
        {props.content.vereinsuebersicht && (
          <span className={styles.sportFactsLinkedItem}>
            <a
              href={props.content.vereinsuebersicht}
              target="_blank"
              rel="noopener noreferrer">
              {props.content.vereinsuebersicht}
            </a>
            <i className="material-icons" aria-hidden="true">
              link
            </i>
          </span>
        )}
      </dd>
    </dl>
  );
}

export default SportFacts;
