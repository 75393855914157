/* React Tinongo <YoutubeVideo> component */

import React, { Component } from 'react';

import styles from './youtube-video.module.scss';

class YoutubeVideo extends Component {
  /**
   * Constructor:
   * Initializes state and binds methods.
   */
  constructor(props) {
    super(props);

    this.setVideoHeight = this.setVideoHeight.bind(this);
  }

  /**
   * Lifecycle hook:
   * Runs after the component output has been rendered to the DOM.
   */
  componentDidMount() {
    this.setVideoHeight();
    window.addEventListener('resize', this.setVideoHeight);
  }

  /**
   * Lifecycle hook:
   * Called if the component is removed from the DOM.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.setVideoHeight);
  }

  /**
   * Updates the video (iframe) height to adapt to different screen/viewport sizes.
   */
  setVideoHeight() {
    const videoWidth = this.video.offsetWidth;
    this.video.style.height = (videoWidth / 16) * 9 + 'px';
  }

  render() {
    return (
      <iframe
        ref={(video) => {
          this.video = video;
        }}
        className={styles.youtubeVideo}
        title={this.props.title}
        src={`https://www.youtube-nocookie.com/embed/${this.props.videoID}?rel=0&ampshowinfo=0`}
        frameBorder="0"
        allowFullScreen></iframe>
    );
  }
}

export default YoutubeVideo;
