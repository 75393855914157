/* React Tinongo <Toc> component */

import React from 'react';

import styles from './toc.module.scss';

/* <Toc> component */
function Toc(props) {
  const tocItems = props.items.map((item) => (
    <li className={styles.tocItem} key={item.href}>
      <a href={item.href} className={styles.tocLink}>
        {item.text}
      </a>
    </li>
  ));

  return (
    <nav>
      <h1 className={styles.tocTitle}>{props.title}</h1>
      <ul className={styles.tocItems}>{tocItems}</ul>
    </nav>
  );
}

export default Toc;
