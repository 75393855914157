/* React Tinongo <Content> component */

import React from 'react';
import classNames from 'classnames';

import styles from './content.module.scss';

/* <Content> component */
function Content(props) {
  const classes = classNames({
    [`${styles.content}`]: !props.hasToolbar,
    [`${styles.contentWithToolbar}`]: props.hasToolbar,
  });

  return <div className={classes}>{props.children}</div>;
}

export default Content;
